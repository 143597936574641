// @flow
import React, {type Node, useCallback, Fragment, lazy, Suspense} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import ModalLoader from 'components/ui/ModalLoader'
import ActionProcessModal from './ActionProcessModal'
import ProjectProcessModal from './ProjectProcessModal'
import TensionProcessModal from './TensionProcessModal'
import AgendaProcessModal from './AgendaProcessModal'
import type {
  ProcessingModals_organization as Organization,
} from './__generated__/ProcessingModals_organization.graphql'
import type {
  ProcessingModals_tension as Tension,
} from './__generated__/ProcessingModals_tension.graphql'
import type {
  ProcessingModals_viewer as Viewer,
} from './__generated__/ProcessingModals_viewer.graphql'

const LazyProposalCreator = lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "ProposalCreator" */
  'components/pages/Proposals/ProposalsView/ProposalCreator'
))

const LazyProposalEditor = lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "ProposalEditor" */
  'components/pages/Proposals/ProposalsView/ProposalEditor'
))

type ProcessModalType = 'Action' | 'Project' | 'AgendaTactical' | 'AgendaGovernance' | 'Proposal'

type Props = $ReadOnly<{
  organization: Organization,
  tension: Tension,
  viewer: Viewer,
  isOpenModal: (ProcessModalType | null) => boolean,
  closeModal: () => void,
  openModal: ({tensionDatabaseId: ?string, processModal: ProcessModalType | null}) => void,
}>

function ProcessingModals({
  organization,
  tension,
  viewer,
  isOpenModal,
  closeModal,
  openModal,
}: Props): Node {
  const asyncCloseModal = useCallback(() => {
    closeModal()
    return Promise.resolve()
  }, [closeModal])

  const asyncOpenModal = useCallback(({tensionDatabaseId, processModal}) => {
    openModal({tensionDatabaseId, processModal})
    return Promise.resolve()
  }, [openModal])

  const proposalId = tension.proposal?.databaseId

  return (
    <Fragment>
      <TensionProcessModal
        tension={tension}
        isOpen={isOpenModal(null)}
        onClose={closeModal}
        openModal={asyncOpenModal}
      />
      <ActionProcessModal
        tension={tension}
        viewer={viewer}
        isOpen={isOpenModal('Action')}
        onClose={closeModal}
      />
      <ProjectProcessModal
        organization={organization}
        tension={tension}
        isOpen={isOpenModal('Project')}
        onClose={closeModal}
      />
      {(isOpenModal('AgendaTactical')) && (
        <AgendaProcessModal
          tension={tension}
          close={closeModal}
          meetingType="TACTICAL"
        />
      )}
      {isOpenModal('AgendaGovernance') && (
        <AgendaProcessModal
          tension={tension}
          close={closeModal}
          meetingType="GOVERNANCE"
        />
      )}
      <Suspense fallback={<ModalLoader />}>
        {isOpenModal('Proposal') && proposalId && (
          <LazyProposalEditor
            orgDatabaseId={organization.databaseId}
            proposalDatabaseId={proposalId}
            toggle={asyncCloseModal}
          />
        )}
        {isOpenModal('Proposal') && !proposalId && (
          <LazyProposalCreator
            orgDatabaseId={organization.databaseId}
            tensionDatabaseId={tension.databaseId}
            toggle={asyncCloseModal}
          />
        )}
      </Suspense>
    </Fragment>
  )
}

export default (createFragmentContainer(ProcessingModals, {
  organization: graphql`
    fragment ProcessingModals_organization on Organization {
      databaseId
      
      ...CreateProjectModal_organization
    }
  `,
  tension: graphql`
    fragment ProcessingModals_tension on Tension {
      databaseId

      proposal {
        databaseId
      }

      ...TensionProcessModal_tension
      ...ActionProcessModal_tension
      ...ProjectProcessModal_tension
      ...AgendaProcessModal_tension
    }
  `,
  viewer: graphql`
    fragment ProcessingModals_viewer on Person {
      ...ActionProcessModal_viewer
    }
  `,
}): RelayFragmentContainer<typeof(ProcessingModals)>)
