// @flow
import type {Node} from 'react'
import React, {Suspense} from 'react'
import {useFragment, graphql} from 'react-relay'

import FrogTipBox from 'components/ui/FrogTipBox'
import NoItemsMessage from 'components/ui/NoItemsMessage'
import Box from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/Box'
import SectionTip from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/SectionTip'

import ModalLoader from 'components/ui/ModalLoader'
import RootErrorBoundary from 'components/OrgNav/OrgNavApp/RootErrorBoundary'
import useModalsState from 'components/checklistItems/useModalsState'

import type {
  ChecklistList_viewer$key as ViewerKey,
} from './__generated__/ChecklistList_viewer.graphql'

import type {
  ChecklistList_organization$key as OrganizationKey,
} from './__generated__/ChecklistList_organization.graphql'

import ChecklistListBody from './ChecklistListBody'
import CreateViewerChecklistItem from './CreateViewerChecklistItem'

type Props = $ReadOnly<{
  viewer: ViewerKey,
  organization: OrganizationKey,
  showAddButton?: boolean,
}>

const viewerFragment = graphql`
  fragment ChecklistList_viewer on Person {
    ...ChecklistListBody_viewer
  }
`

const organizationFragment = graphql`
  fragment ChecklistList_organization on Organization {
    id
    onRestrictedPlan

    rules {
      ruleKey
      ruleValue
    }
  }
`

function ChecklistList({viewer: viewerKey, organization: organizationKey, showAddButton}: Props): Node {
  const viewer = useFragment(viewerFragment, viewerKey)
  const organization = useFragment(organizationFragment, organizationKey)

  const rulesOfCooperation = organization.rules.find((rule) => rule.ruleKey === 'duties')?.ruleValue || 'HOLACRACY'

  const [modalsState, {openCreator, closeModal}] = useModalsState()
  const addingButtonConfig = {
    itemName: I18n.t('role_notes.item'),
    onClick: () => openCreator(),
  }

  const checkListTip = () => (
    <SectionTip
      rule={rulesOfCooperation}
      holacracyTip={I18n.t('org_nav.tabs.checklists_tab.markdown_tip')}
      holacracyBottomLink={I18n.t('org_nav.tabs.checklists_tab.learn_more_link')}
      nonHolacracyTip={I18n.t('org_nav.tabs.checklists_tab.markdown_tip_non_holacracy')}
    />
  )

  const renderModal = () => (
    <Suspense fallback={<ModalLoader />}>
      <RootErrorBoundary>
        <CreateViewerChecklistItem
          orgId={organization.id}
          close={closeModal}
        />
      </RootErrorBoundary>
    </Suspense>
  )

  const renderBody = () => (
    organization.onRestrictedPlan
      ? <NoItemsMessage message={I18n.t('org_nav.tabs.checklists_tab.upgrade_to_premium')} />
      : <ChecklistListBody viewer={viewer} />
  )

  return (
    <Box id="checklists">
      {modalsState.type === 'CREATE_CHECKLIST_ITEM' && renderModal()}
      <FrogTipBox
        helpFor={I18n.t('org_nav.tabs.checklists')}
        target={I18n.t('org_nav.tabs.checklists')}
        tip={checkListTip}
        addingButtonConfig={(!organization.onRestrictedPlan && showAddButton) ? addingButtonConfig : null}
        boxHeader
      />
      {renderBody()}
    </Box>
  )
}

ChecklistList.defaultProps = {
  showAddButton: true,
}

export default ChecklistList
