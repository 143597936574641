// @flow
import type {Element, Node} from 'react'
import React from 'react'
import classNames from 'classnames'
import styles from './index.scss'
import ListItem from './ListItem'
import ListRow from './ListRow'

type GenericItem = $ReadOnly<{
  id: string | number,
  ...
}>

type Props<Item> = $ReadOnly<{
  'test-id': string,
  items: $ReadOnlyArray<Item>,
  placeholder?: Node,
  hasTopBorder: boolean,
  renderItem: Item => Node,
}>

function AccessibleList<Item: GenericItem>({items, placeholder, renderItem, hasTopBorder, 'test-id': testId}: Props<Item>): Element<"div"> | Element<"ul"> {
  if (items.length === 0 && placeholder) {
    return (
      <div className={styles.placeholder} test-id={`${testId}-placeholder`}>
        {placeholder}
      </div>
    )
  }

  const containerStyle = classNames(styles.container, {
    [styles.noTopBorder]: !hasTopBorder,
  })

  return (
    <ul className={containerStyle} test-id={testId}>
      {items.map((item) => (
        <li key={item.id} className={styles.item}>
          {renderItem(item)}
        </li>
      ))}
    </ul>
  )
}

AccessibleList.defaultProps = {
  hasTopBorder: false,
  placeholder: null,
}

AccessibleList.ListItem = ListItem
AccessibleList.ListRow = ListRow

export default AccessibleList
