/**
 * @flow
 * @relayHash 90cc070b5a6a96a8d159111210c6f40b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProjectFormModalView_circle$ref = any;
type ProjectFormModalView_organization$ref = any;
type ProjectFormModalView_project$ref = any;
type ProjectFormModalView_tension$ref = any;
type ProjectFormModalView_viewer$ref = any;
export type ProjectFormModalQueryVariables = {|
  orgDatabaseId: string,
  circleDatabaseId: string,
  tensionDatabaseId: string,
  projectId: string,
|};
export type ProjectFormModalQueryResponse = {|
  +organization: ?{|
    +viewer: {|
      +$fragmentRefs: ProjectFormModalView_viewer$ref
    |},
    +circle: ?{|
      +$fragmentRefs: ProjectFormModalView_circle$ref
    |},
    +tension: ?{|
      +$fragmentRefs: ProjectFormModalView_tension$ref
    |},
    +$fragmentRefs: ProjectFormModalView_organization$ref,
  |},
  +project: ?{|
    +id: string,
    +$fragmentRefs: ProjectFormModalView_project$ref,
  |},
|};
export type ProjectFormModalQuery = {|
  variables: ProjectFormModalQueryVariables,
  response: ProjectFormModalQueryResponse,
|};
*/


/*
query ProjectFormModalQuery(
  $orgDatabaseId: String!
  $circleDatabaseId: String!
  $tensionDatabaseId: String!
  $projectId: ID!
) {
  organization(databaseId: $orgDatabaseId) {
    viewer {
      ...ProjectFormModalView_viewer
      id
    }
    circle(databaseId: $circleDatabaseId) {
      ...ProjectFormModalView_circle
      id
    }
    tension(databaseId: $tensionDatabaseId) {
      ...ProjectFormModalView_tension
      id
    }
    ...ProjectFormModalView_organization
    id
  }
  project: node(id: $projectId) {
    __typename
    id
    ... on Project {
      ...ProjectFormModalView_project
    }
  }
}

fragment ActionCompletionCheckbox_action on Action {
  id
  completedAt
}

fragment Actions_project on Project {
  viewerCanAddActions
  ...List_project
}

fragment Body_action on Action {
  id
  completedAt
  description(format: HTML)
  rawDescription: description(format: PLAIN)
  ...ActionCompletionCheckbox_action
}

fragment CircleAndRoleSelector_project on Project {
  circle {
    id
    supportedRole {
      id
      isDisplayedAsCircle
      ...RoleSelector_roles
    }
  }
}

fragment CircleAndRoleSelector_viewer on Person {
  circles(first: 100) {
    edges {
      node {
        id
        supportedRole {
          id
          isDisplayedAsCircle
          ...RoleSelector_roles
        }
      }
    }
  }
  ...PersonRoleSelector_person
  ...PersonCircleSelector_person
}

fragment CircleSelector_circles on Circle {
  id
  databaseId
  localizedName
  isGovernanceEnabled
  supportedRole {
    isDisplayedAsCircle
    id
  }
}

fragment Item_action on Action {
  id
  privateToCircle
  trigger
  circle {
    id
  }
  person {
    id
  }
  ...Body_action
}

fragment Item_project on Project {
  id
  circle {
    id
  }
  person {
    id
  }
}

fragment List_project on Project {
  id
  actions(first: 100) {
    edges {
      node {
        id
        position
        description(format: HTML)
        rawDescription: description(format: PLAIN)
        viewerCanUpdate
        ...Item_action
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ...Item_project
}

fragment PeopleSelector_people on Person {
  id
  databaseId
  name
}

fragment PersonCircleSelector_person on Person {
  circles(first: 100) {
    edges {
      node {
        ...CircleSelector_circles
        id
      }
    }
  }
}

fragment PersonRoleSelector_person on Person {
  roles {
    edges {
      node {
        circle {
          supportedRole {
            isDisplayedAsCircle
            id
          }
          id
        }
        ...RoleSelector_roles_4EQjyB
        id
      }
    }
  }
}

fragment ProjectEditForm_circle on Circle {
  ...ProjectOwnerSelector_circle
  roles(includeFocusRoles: true) {
    edges {
      node {
        id
        ...RoleAccountabilitySelector_role
      }
    }
  }
}

fragment ProjectEditForm_organization on Organization {
  databaseId
  isGoalsEnabled
  isROIEnabled
  supportableGoalsAndTargets
  onRestrictedPlan
  showAccountabilities: featureEnabled(name: "accountabilities")
  ...WYSIWYGTextareaWithFeatureFlag_organization
}

fragment ProjectEditForm_project on Project {
  ...Actions_project
  ...ProjectOwnerSelector_project
}

fragment ProjectEditForm_viewer on Person {
  ...ProjectOwnerSelector_viewer
  roles {
    edges {
      node {
        id
        ...RoleAccountabilitySelector_role
      }
    }
  }
}

fragment ProjectFormModalView_circle on Circle {
  ...ProjectEditForm_circle
}

fragment ProjectFormModalView_organization on Organization {
  ...ProjectEditForm_organization
}

fragment ProjectFormModalView_project on Project {
  visibility
  ...ProjectEditForm_project
}

fragment ProjectFormModalView_tension on Tension {
  ...UpdateTensionInlineBox_tension
  ...TensionInfo_tension
}

fragment ProjectFormModalView_viewer on Person {
  ...ProjectEditForm_viewer
  ...UpdateTensionInlineBox_viewer
}

fragment ProjectOwnerSelector_circle on Circle {
  ...RoleAndPersonSelector_circle
}

fragment ProjectOwnerSelector_project on Project {
  ...RoleAndPersonSelector_project
  ...CircleAndRoleSelector_project
}

fragment ProjectOwnerSelector_viewer on Person {
  ...CircleAndRoleSelector_viewer
}

fragment RoleAccountabilitySelector_role on Role {
  isLinked
  purpose
  accountabilities {
    edges {
      node {
        id
        description(format: PLAIN)
      }
    }
  }
}

fragment RoleAndPersonSelector_circle on Circle {
  individualAction {
    id
  }
  supportedRole {
    id
    isExpanded
    assignments {
      edges {
        node {
          person {
            id
            ...PeopleSelector_people
          }
          id
        }
      }
    }
    ...RoleSelector_roles
  }
  roles(includeFocusRoles: true) {
    edges {
      node {
        id
        isIndividualAction
        assignments {
          edges {
            node {
              person {
                id
                ...PeopleSelector_people
              }
              id
            }
          }
        }
        ...RoleSelector_roles
      }
    }
  }
  members {
    edges {
      node {
        id
        ...PeopleSelector_people
      }
    }
  }
}

fragment RoleAndPersonSelector_project on Project {
  person {
    id
    ...PeopleSelector_people
  }
  role {
    isIndividualAction
    id
  }
}

fragment RoleSelector_roles on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE_FOR_CORE_ROLES)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}

fragment RoleSelector_roles_4EQjyB on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}

fragment TensionEditForm_organization on Organization {
  databaseId
  ...WYSIWYGTextareaWithFeatureFlag_organization
}

fragment TensionEditForm_viewer on Person {
  ...PersonRoleSelector_person
}

fragment TensionInfo_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment TensionInfo_tension on Tension {
  createdAt
  body
  agendaLabel
  impactedRole {
    localizedName
    id
  }
  person {
    id
    name
    isActive
  }
  organization {
    ...TensionInfo_organization
    id
  }
}

fragment UpdateTensionInlineBox_tension on Tension {
  id
  body
  organization {
    ...TensionEditForm_organization
    id
  }
  impactedRole {
    id
  }
}

fragment UpdateTensionInlineBox_viewer on Person {
  ...TensionEditForm_viewer
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "circleDatabaseId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orgDatabaseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tensionDatabaseId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "circleDatabaseId"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "tensionDatabaseId"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDisplayedAsCircle",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFocus",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "governanceEnabled",
  "storageKey": null
},
v14 = {
  "alias": "nameWithMode",
  "args": [
    {
      "kind": "Literal",
      "name": "with",
      "value": "CIRCLE_FOR_CORE_ROLES"
    }
  ],
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": "localizedName(with:\"CIRCLE_FOR_CORE_ROLES\")"
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Circle",
  "kind": "LinkedField",
  "name": "circle",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "supportedRole",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        (v12/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "supportedRole",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    (v15/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLinked",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purpose",
  "storageKey": null
},
v20 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "PLAIN"
  }
],
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountabilityConnection",
  "kind": "LinkedField",
  "name": "accountabilities",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountabilityEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Accountability",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v8/*: any*/),
            {
              "alias": null,
              "args": (v20/*: any*/),
              "kind": "ScalarField",
              "name": "description",
              "storageKey": "description(format:\"PLAIN\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = [
  (v8/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v24 = [
  (v8/*: any*/),
  (v12/*: any*/),
  (v23/*: any*/)
],
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": (v24/*: any*/),
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "AssignmentConnection",
  "kind": "LinkedField",
  "name": "assignments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Assignment",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v25/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isIndividualAction",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWysiwygEnabled",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectFormModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProjectFormModalView_viewer"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Circle",
            "kind": "LinkedField",
            "name": "circle",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProjectFormModalView_circle"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Tension",
            "kind": "LinkedField",
            "name": "tension",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProjectFormModalView_tension"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectFormModalView_organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "project",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProjectFormModalView_project"
              }
            ],
            "type": "Project",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectFormModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "CircleConnection",
                "kind": "LinkedField",
                "name": "circles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CircleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Circle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v16/*: any*/),
                          (v12/*: any*/),
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isGovernanceEnabled",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "circles(first:100)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Circle",
                            "kind": "LinkedField",
                            "name": "circle",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "supportedRole",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v8/*: any*/),
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v8/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": "nameWithMode",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "with",
                                "value": "CIRCLE"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "localizedName",
                            "storageKey": "localizedName(with:\"CIRCLE\")"
                          },
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v21/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Circle",
            "kind": "LinkedField",
            "name": "circle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "individualAction",
                "plural": false,
                "selections": (v22/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "supportedRole",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isExpanded",
                    "storageKey": null
                  },
                  (v26/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "includeFocusRoles",
                    "value": true
                  }
                ],
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v27/*: any*/),
                          (v26/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v21/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "roles(includeFocusRoles:true)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v24/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Tension",
            "kind": "LinkedField",
            "name": "tension",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v28/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "impactedRole",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "agendaLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v23/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isGoalsEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isROIEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "supportableGoalsAndTargets",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onRestrictedPlan",
            "storageKey": null
          },
          {
            "alias": "showAccountabilities",
            "args": [
              {
                "kind": "Literal",
                "name": "name",
                "value": "accountabilities"
              }
            ],
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"accountabilities\")"
          },
          (v28/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "project",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v29/*: any*/),
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visibility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanAddActions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "ActionConnection",
                "kind": "LinkedField",
                "name": "actions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Action",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "position",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "format",
                                "value": "HTML"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": "description(format:\"HTML\")"
                          },
                          {
                            "alias": "rawDescription",
                            "args": (v20/*: any*/),
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": "description(format:\"PLAIN\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewerCanUpdate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "privateToCircle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "trigger",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Circle",
                            "kind": "LinkedField",
                            "name": "circle",
                            "plural": false,
                            "selections": (v22/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Person",
                            "kind": "LinkedField",
                            "name": "person",
                            "plural": false,
                            "selections": (v22/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "completedAt",
                            "storageKey": null
                          },
                          (v29/*: any*/),
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isUpdating",
                                "storageKey": null
                              }
                            ]
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "actions(first:100)"
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "List_actions",
                "kind": "LinkedHandle",
                "name": "actions"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Circle",
                "kind": "LinkedField",
                "name": "circle",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              (v25/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v27/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Project",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "90cc070b5a6a96a8d159111210c6f40b",
    "metadata": {},
    "name": "ProjectFormModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ff31e0956153b17aed927468e8942bfd';

module.exports = node;
