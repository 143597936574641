// @flow
import type {ComponentType, Node} from 'react'
import React, {lazy, useState, Suspense} from 'react'
import LoadingDots from 'components/ui/LoadingDots'

const WYSIWYGTextarea: ComponentType<*> | string = lazy(() => import(
  /* webpackChunkName: "WYSIWYGTextarea" */
  '../WYSIWYGTextarea'
))

export type Props = $ReadOnly<{
  fieldName: string,
  fieldValue: string,
  rows?: number,
}>

function RailsWYSIWYG({fieldName, fieldValue, rows}: Props): null | Node {
  const [newValue, setNewValue] = useState(fieldValue)

  const updateHiddenField = (event) => {
    setNewValue(event.target.value)
  }

  return (
    <div>
      <Suspense fallback={<LoadingDots />}>
        <WYSIWYGTextarea
          onChange={updateHiddenField}
          rows={rows}
          value={fieldValue}
        />
      </Suspense>
      <textarea
        className="hidden"
        name={fieldName}
        readOnly
        value={newValue}
      />
    </div>
  )
}

RailsWYSIWYG.defaultProps = {
  rows: 5,
}

export default RailsWYSIWYG
