import {RecordSourceInspector} from 'relay-runtime'
import components from './components'
import environment, {setGlobalRelayWaitForIt} from './environment'
import utils from './utils'

function getInspector() {
  /* eslint-disable no-underscore-dangle */
  return new RecordSourceInspector(environment._store._recordSource)
}

export default {
  components,
  utils,

  relay: {
    setGlobalRelayWaitForIt,
    environment,
    getInspector,
  },
}
