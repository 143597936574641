import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import $ from 'jquery'
import _ from 'underscore'
import getStatusText from 'utils/projects/getStatusText'
import util from 'utils/MiscUtils'
import render from 'utils/MiscUtils/render'
import DefaultTabs from 'components/DefaultTabs'

/* eslint-disable func-names */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/label-has-associated-control */

const SearchProjectsComponent = createReactClass({
  displayName: 'SearchProjectsComponent',

  propTypes: {
    tabs: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      keyword: '',
      status: ['Current', 'Waiting'],
      circles: 'my',
      created_after: '',
      created_before: '',
      projects: [],
    }
  },

  componentDidMount() {
    this.updateProjectsList()
    $('.created_after.datepicker').datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: $.proxy(function (date) {
        this.setState({created_after: date}, this.updateProjectsList)
      }, this),
    })
    $('.created_before.datepicker').datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: $.proxy(function (date) {
        this.setState({created_before: date}, this.updateProjectsList)
      }, this),
    })
  },

  onSearchResponse(apiReqRes) {
    const projectData = apiReqRes.response.data.projects || util.error('expected projects')

    this.setState({projects: projectData})
  },

  resultCountLimit: 250,

  updateProjectsList() {
    const allParams = {
      keyword: this.state.keyword,
      status: this.state.status,
      circles: this.state.circles,
      created_after: this.state.created_after,
      created_before: this.state.created_before, // "YYYY-MM-DD"
      limit: this.resultCountLimit,
    }

    const paramsToSend = {
      include: '', // prevent side-loading and improve performance
      ..._.omit(allParams, (v) => !v),
    }

    gf.app.apiAdapter.apiGet('projects', {params: paramsToSend}).then(this.onSearchResponse)
  },

  handleFieldChange(e) {
    const data = {}
    data[e.target.name] = e.target.value
    this.setState(data)

    clearTimeout(this.timeoutId)
    this.timeoutId = setTimeout($.proxy(function () {
      this.updateProjectsList()
    }, this), 500)
  },

  handleStatusChange(e) {
    let subData = this.state[e.target.name]
    if (e.target.checked)
      subData.push(e.target.value)
    else
      subData = _.without(subData, e.target.value)

    const data = {}
    data[e.target.name] = subData
    this.setState(data, this.updateProjectsList)
  },

  handleCircleChange(e) {
    const data = {}
    data[e.target.name] = e.target.value
    this.setState(data, this.updateProjectsList)
  },

  formatDate(raw) {
    if (raw)
      return raw.substring(0, raw.indexOf('T'))

    return undefined
  },

  formatROI(raw) {
    if (raw)
      return raw.toFixed(2)

    return undefined
  },

  isValidDate(dateString) {
    // moment.js treats "199-" as a valid date, so not appropriate in this context
    const yearMonthDateFormat = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/
    return !dateString || (dateString.match(yearMonthDateFormat) && Date.parse(dateString))
  },

  renderSpinner() {
    if (!this.state.projects)
      return (<div className="loading" />)

    return null
  },

  renderRows() {
    if (this.state.projects) {
      const rows = this.state.projects.map((project) => (
        <div key={project.id} className="tr stripe">
          {this.renderStatus(project)}
          <div className="td date">{this.formatDate(project.created_at)}</div>
          {this.renderArchived(project.archived_at)}
          <div className="td">
            {render.data('circles', project.links.circle, 'name', {params: {include: ''}})}
            {':  '}
            {render.data('roles', project.links.role, 'name', {params: {include: ''}, allowMissingId: true})}
          </div>
          <div className="td">{project.description}</div>
          <div className="td">{project.value}</div>
          <div className="td">{project.effort}</div>
          <div className="td">{this.formatROI(project.roi)}</div>
          <div className="td nobreak align-right">
            {this.renderProjectLink(project.link)}
            {' '}
            {this.renderPrivateToCircle(project.private_to_circle)}
          </div>
        </div>
      ))
      return (<div className="tbody zebra interactive">{rows}</div>)
    }

    return null
  },

  renderResultsLimitMsg() {
    if (this.state.projects?.length >= this.resultCountLimit) {
      return (
        <div>{I18n.t('project_search.result_count_limit', {number: this.resultCountLimit})}</div>
      )
    }
    return null
  },

  renderProjectLink(url) {
    if (!url)
      return null

    const title = `${I18n.t('projects.links_to')}: ${url}`

    return (
      <span className="icon-shell">
        <a
          className="fa external-link use-tipsy"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          title={title}
          aria-label={title}
        />
      </span>
    )
  },

  renderPrivateToCircle(val) {
    if (val) {
      return (
        <span className="icon-shell">
          <span
            className="fa private-icon use-tipsy"
            title={I18n.t('projects.project_is_private_tip')}
          />
        </span>
      )
    }

    return null
  },

  renderInput(obj) {
    // big assumption that the the values in state and the input name/value all match!
    let checked = false
    if ($.isArray(this.state[obj.name]))
      checked = $.inArray(obj.value, this.state[obj.name]) !== -1
    else
      checked = this.state[obj.name] === obj.value

    return (
      <input
        type={obj.type}
        value={obj.value || ''}
        name={obj.name}
        onChange={obj.onChange}
        checked={checked}
      />
    )
  },

  renderStatus(project) {
    const status = project.status ? project.status.toLowerCase() : null
    const value = status ? getStatusText(status) : null

    return (
      <div className="td">
        {value}
      </div>
    )
  },

  renderArchivedHeader() {
    if ($.inArray('Archived', this.state.status) !== -1)
      return (<div className="th">{'Archived'}</div>)

    return null
  },

  renderArchived(raw) {
    if ($.inArray('Archived', this.state.status) !== -1) {
      return (
        <div className="td date">{this.formatDate(raw)}</div>
      )
    }

    return null
  },

  render() {
    return (
      <div className="page__content">
        <header className="page__header">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="page__title">{I18n.t('reports.title')}</h1>
              </div>
            </div>
          </div>
        </header>

        <main className="page__main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <DefaultTabs tabs={this.props.tabs} currentTab="reports_projects" isOnRestricted={false} />
                <div className="filters row">
                  <div className="filter-section status col-lg-4">
                    <div className="form-group">
                      <div className="label">{I18n.t('project_search.description_keywords')}</div>
                      <input name="keyword" className="keyword form-control" type="text" placeholder="" value={this.state.keyword} onChange={this.handleFieldChange} />
                    </div>
                    <div className="mb-2">
                      <label className="mr-3">
                        {this.renderInput({
                          type: 'radio',
                          value: 'my',
                          name: 'circles',
                          onChange: this.handleCircleChange,
                        })}
                        {' '}
                        {I18n.t('project_search.my_circles')}
                      </label>
                      <label>
                        {this.renderInput({
                          type: 'radio',
                          value: 'all',
                          name: 'circles',
                          onChange: this.handleCircleChange,
                        })}
                        {' '}
                        {I18n.t('project_search.all_circles')}
                      </label>
                    </div>
                  </div>
                  <div className="filter-section created_date col-lg-4">
                    <div className={`form-group mb-3${this.isValidDate(this.state.created_after) ? '' : ' has-danger'}`}>
                      <div className="label">{I18n.t('project_search.date_created')}</div>
                      <input
                        type="text"
                        className="created_after datepicker form-control"
                        placeholder={`${I18n.t('project_search.from')} (YYYY-MM-DD)`}
                        name="created_after"
                        onChange={this.handleFieldChange}
                      />
                    </div>
                    <div className={`form-group${this.isValidDate(this.state.created_before) ? '' : ' has-danger'}`}>
                      <input
                        type="text"
                        className="created_before datepicker form-control"
                        placeholder={`${I18n.t('project_search.to')} (YYYY-MM-DD)`}
                        name="created_before"
                        onChange={this.handleFieldChange}
                      />
                    </div>
                  </div>
                  <div className="filter-section status col-lg-4">
                    <div className="label">{I18n.t('shared.status')}</div>
                    <div className="d-flex">
                      <div className="mr-4">
                        <div>
                          <label>
                            {this.renderInput({type: 'checkbox',
                              value: 'Current',
                              name: 'status',
                              onChange: this.handleStatusChange})}
                            {' '}
                            {I18n.t('project_search.current')}
                          </label>
                        </div>
                        <div>
                          <label>
                            {this.renderInput({type: 'checkbox',
                              value: 'Waiting',
                              name: 'status',
                              onChange: this.handleStatusChange})}
                            {' '}
                            {I18n.t('project_search.waiting')}
                          </label>
                        </div>
                        <div>
                          <label>
                            {this.renderInput({type: 'checkbox',
                              value: 'Done',
                              name: 'status',
                              onChange: this.handleStatusChange})}
                            {' '}
                            {I18n.t('project_search.done')}
                          </label>
                        </div>
                      </div>
                      <div className="">
                        <div>
                          <label>
                            {this.renderInput({type: 'checkbox',
                              value: 'Future',
                              name: 'status',
                              onChange: this.handleStatusChange})}
                            {' '}
                            {I18n.t('project_search.future')}
                          </label>
                        </div>
                        <label>
                          {this.renderInput({
                            type: 'checkbox',
                            value: 'Archived',
                            name: 'status',
                            onChange: this.handleStatusChange,
                          })}
                          {' '}
                          {I18n.t('project_search.archived')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <h2>
                  {I18n.t('project_search.results')}
                  {' '}
                  {this.state.projects ? `(${this.state.projects.length})` : ''}
                </h2>
                {this.renderResultsLimitMsg()}
                <div className="table full results">
                  <div className="tr">
                    <div className="th">{I18n.t('shared.status')}</div>
                    <div className="th">{I18n.t('shared.created')}</div>
                    {this.renderArchivedHeader()}
                    <div className="th">{I18n.t('project_search.circle_and_role')}</div>
                    <div className="th">{I18n.t('project_search.desired_outcome')}</div>
                    <div className="th">{I18n.t('shared.value')}</div>
                    <div className="th">{I18n.t('project_search.effort')}</div>
                    <div className="th">{I18n.t('project_search.roi')}</div>
                    <div className="th" />
                  </div>
                  {this.renderRows()}
                </div>
              </div>
            </div>
            {this.renderSpinner()}
          </div>
        </main>
      </div>
    )
  },
})

export default SearchProjectsComponent
