/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type TensionEditForm_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UpdateTensionInlineBox_tension$ref: FragmentReference;
declare export opaque type UpdateTensionInlineBox_tension$fragmentType: UpdateTensionInlineBox_tension$ref;
export type UpdateTensionInlineBox_tension = {|
  +id: string,
  +body: string,
  +organization: {|
    +$fragmentRefs: TensionEditForm_organization$ref
  |},
  +impactedRole: ?{|
    +id: string
  |},
  +$refType: UpdateTensionInlineBox_tension$ref,
|};
export type UpdateTensionInlineBox_tension$data = UpdateTensionInlineBox_tension;
export type UpdateTensionInlineBox_tension$key = {
  +$data?: UpdateTensionInlineBox_tension$data,
  +$fragmentRefs: UpdateTensionInlineBox_tension$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateTensionInlineBox_tension",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TensionEditForm_organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "impactedRole",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Tension",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '30742307c9594415566a3750e59d5ce8';

module.exports = node;
